nav {
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 1;
  position: fixed;
  width: 100%;
  background: rgb(255,255,255);
}
img{
  border:0 none;
  background: transparent;
  height: 40px;
  }
  .link {
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    color: white;
    color: rgb(0, 0, 0);
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
  }

  .menu-items {
    display: flex;
    align-items: center;
    padding-left: 50%;
  }
  .mobile-menu-icon{
    display: none
  }


  @media only screen and (max-width: 768px) {
    .menu-items {
    display: none;
    }
  .mobile-menu-icon {
    display: block;
    color: rgb(5, 5, 5);
    display: flex;
    align-items: center;
    font-size: 50px;
    cursor: pointer;
    top: 0;
    justify-content: right;
  }
  }