.sidebar-container {
    position: fixed;
    z-index: 2;
    width: 25%;
    height: auto;
    background: #000d1a;
    display: grid;
    align-items: center;
    right: 0;
    transition: 0.3s ease-in-out;
}

.opacity-on{
    opacity: 1;
    top: 0;
}
.opacity-off{
    opacity: 0;
    top: -100%;
}
.icon{
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    color: white;
    position: absolute;
    top: 2rem;
    right: 2.5rem;
}
.close-icon{
    color : whitesmoke
}

.sidebar-menu{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 80px);
    text-align: center;
    margin-bottom: 4rem;
    padding: 40px 25px 30px 0px;
}

.sidebar-links{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    transition: 0.2s ease-in-out ;
}

@media only screen and (max-width: 480px){
    .sidebar-menu{
        grid-template-rows: repeat(4, 60px)
    }
}
