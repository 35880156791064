body, html  {
  box-sizing: border-box;
  margin: 0;
  font-size: 16px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-image: url("../src/images/background.jpg");
    background-position: 'center';
    background-size : 'cover';
    background-repeat: 'no-repeat';
    width: '100vw';
    height: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
